// import { useEffect, useRef, useState } from "react";
// import React from "react";
// import TextField from "@mui/material/TextField";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// import "./Address.css";

// let lat;
// let lng;
// let current = "";
// let flag = 2;
// let map, marker, infowindow;
// const Address = (props) => {
//   const pickupAddressRef = useRef();
//   const dropAddressRef = useRef();
//   const [pickupStopDetails, setPickupStopDetails] = useState();
//   const [dropStopDetails, setDropStopDetails] = useState();
//   const [isCustomStopSelected, setIsCustomStopSelected] = useState(false);

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
//     script.async = true;

//     document.body.appendChild(script);
//   }, [isCustomStopSelected]);

//   useEffect(() => {
//     // console.log("a", props.riderData);
//     if (props.edit && props.riderData) {
//       pickupAddressRef.current.value = props.riderData[0]?.PickupPoint;
//       dropAddressRef.current.value = props.riderData[0]?.DropPoint;
//     }
//   });

//   function initMap() {
//     map = new window.google.maps.Map(document.getElementById("map"), {
//       center: props.edit
//         ? {
//             lat: +props.riderData[0].DropLL.split(",")[0],
//             lng: +props.riderData[0].DropLL.split(",")[1],
//           }
//         : { lat: 23.0225, lng: 72.5714 },
//       zoom: props.edit ? 15 : 11,
//       disableDefaultUI: true,
//       fullscreenControl: true,
//       zoomControl: true,
//     });
//     var input1 = document.getElementById("pac-input1");
//     var input2 = document.getElementById("pac-input2");

//     var autocomplete = [];
//     autocomplete[0] = new window.google.maps.places.Autocomplete(input1);
//     autocomplete[1] = new window.google.maps.places.Autocomplete(input2);
//     var geocoder = new window.google.maps.Geocoder();

//     autocomplete[0].bindTo("bounds", map);
//     autocomplete[1].bindTo("bounds", map);
//     infowindow = new window.google.maps.InfoWindow();
//     var infowindowContent = document.getElementById("infowindow-content");
//     infowindow.setContent(infowindowContent);
//     marker = new window.google.maps.Marker({
//       position: props.edit
//         ? {
//             lat: +props.riderData[0].DropLL.split(",")[0],
//             lng: +props.riderData[0].DropLL.split(",")[1],
//           }
//         : "",
//       map: map,
//       draggable: true,
//       animation: window.google.maps.Animation.DROP,
//       // myTitle: props.edit ? props.riderData[0].DropPoint : "",
//       anchorPoint: new window.google.maps.Point(0, -29),
//     });

//     // if (props.edit && flag > 0) {
//     //   const infoWindow = new window.google.maps.InfoWindow();
//     //   marker.addListener("mouseover", () => {
//     //     infoWindow.close();
//     //     infoWindow.setContent(marker.myTitle);
//     //     infoWindow.open(marker.getMap(), marker);
//     //   });
//     //   flag--;
//     // }

//     window.google.maps.event.addListener(marker, "dragend", function (marker) {
//       geocoder.geocode(
//         {
//           latLng: marker.latLng,
//         },
//         function (value) {
//           infowindowContent.children["place-address"].textContent =
//             value[0].formatted_address;
//           infowindowContent.children["place-name"].textContent = "";
//           if (current === "pickup")
//             pickupAddressRef.current.value = value[0].formatted_address;
//           else dropAddressRef.current.value = value[0].formatted_address;
//           addressChangeHandler();
//         }
//       );
//       // console.log(marker.formatted_address);
//       lat = marker.latLng.lat();
//       lng = marker.latLng.lng();
//     });

//     for (let i = 0; i < 2; i++) {
//       autocomplete[i].addListener("place_changed", function () {
//         infowindow.close();
//         marker.setVisible(false);
//         var place = autocomplete[i].getPlace();
//         if (!place.geometry || !place.geometry.location) {
//           window.alert("No details available for input: '" + place.name + "'");
//           return;
//         }
//         if (place.geometry.viewport) {
//           console.log(place.geometry.location);
//           map.setCenter(place.geometry.location);
//           map.setZoom(18);
//         } else {
//           map.setCenter(place.geometry.location);
//           map.setZoom(13);
//         }
//         console.log(place.geometry.location);
//         marker.setPosition(place.geometry.location);
//         marker.setVisible(true);
//         lat = marker.getPosition().lat();
//         lng = marker.getPosition().lng();
//         console.log(marker.getPosition().lat());
//         console.log(marker.getPosition().lng());
//         infowindowContent.children["place-name"].textContent = place.name;
//         infowindowContent.children["place-address"].textContent =
//           place.formatted_address;
//         infowindow.open(map, marker);
//       });
//     }
//   }
//   window.initMap = initMap;

//   const addressChangeHandler = () => {
//     setTimeout(() => {
//       if (current === "pickup" && pickupAddressRef.current.value) {
//         props.addressEntered(current, pickupAddressRef.current.value, lat, lng);
//       } else if (current === "drop" && dropAddressRef.current.value) {
//         props.addressEntered(current, dropAddressRef.current.value, lat, lng);
//       }
//     }, 1000);
//   };

//   const stopChnageHandler = (stopName, type) => {
//     if (stopName.toLowerCase() === "custom") {
//       marker.setDraggable(true);
//       marker.setVisible(false);
//       map.setCenter({ lat: 23.0225, lng: 72.5714 });
//       map.setZoom(11);
//       infowindow.close();
//       setIsCustomStopSelected(true);
//     } else {
//       let desiredStop = props.stops.filter(
//         (val) => val.StopName === stopName
//       )[0];
//       lat = +desiredStop.StopLatlong.split(",")[0];
//       lng = +desiredStop.StopLatlong.split(",")[1];
//       map.setCenter({ lat: lat, lng: lng });
//       marker.setVisible(true);
//       marker.setPosition({ lat: lat, lng: lng });
//       marker.setDraggable(false);
//       map.setZoom(18);
//       infowindow.open(map, marker);
//       infowindow.setContent(stopName);
//       props.addressEntered(type, stopName, lat, lng);
//       setIsCustomStopSelected(false);
//     }
//     if (type === "pickup") setPickupStopDetails(stopName);
//     else setDropStopDetails(stopName);
//   };

//   return (
//     <div style={{ height: "100%" }}>
//       <h4 className="sub-header-title">
//         {props.type === "sc" ? "Bus Stop Details" : "Bus/ Cab Stop Details"}
//       </h4>
//       <div id="pac-container" className="stop-details">
//         <div className="stop-details__pickup">
//           {props.stops.length <= 1 ||
//           pickupStopDetails?.toLowerCase() === "custom" ? (
//             <TextField
//               id="pac-input2"
//               label="To"
//               autoFocus
//               variant="standard"
//               inputRef={pickupAddressRef}
//               sx={{ width: "65%" }}
//             />
//           ) : (
//             <FormControl variant="standard" sx={{ width: "65%" }}>
//               <InputLabel id="demo-simple-select-standard-label">
//                 From
//               </InputLabel>
//               <Select
//                 labelId="demo-simple-select-standard-label"
//                 id="demo-simple-select-standard"
//                 value={pickupStopDetails}
//                 onChange={(e) => stopChnageHandler(e.target.value, "pickup")}
//                 label="From"
//               >
//                 {props.stops?.map((val) => (
//                   <MenuItem
//                     sx={{
//                       display: "block",
//                       width: "400px",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                     }}
//                     value={val.StopName}
//                   >
//                     {val.StopName}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//           <ArrowRightAltIcon sx={{ alignSelf: "flex-end" }} />
//           <TextField
//             id="standard-basic"
//             label="To"
//             value={props.corpName}
//             variant="standard"
//             disabled
//             sx={{ width: "25%" }}
//           />
//         </div>
//         <div className="stop-details__drop">
//           <TextField
//             id="standard-basic"
//             label="From"
//             value={props.corpName}
//             variant="standard"
//             disabled
//             sx={{ width: "25%" }}
//           />
//           <ArrowRightAltIcon sx={{ alignSelf: "flex-end" }} />
//           {props.stops.length <= 1 ||
//           dropStopDetails?.toLowerCase() === "custom" ? (
//             <TextField
//               id="pac-input1"
//               label="To"
//               variant="standard"
//               autoFocus
//               inputRef={dropAddressRef}
//               sx={{ width: "65%" }}
//             />
//           ) : (
//             <FormControl variant="standard" sx={{ width: "65%" }}>
//               <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
//               <Select
//                 labelId="demo-simple-select-standard-label"
//                 id="demo-simple-select-standard"
//                 value={dropStopDetails}
//                 onChange={(e) => stopChnageHandler(e.target.value, "drop")}
//                 label="To"
//               >
//                 {props.stops?.map((val) => (
//                   <MenuItem
//                     sx={{
//                       display: "block",
//                       width: "400px",
//                       textOverflow: "ellipsis",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                     }}
//                     value={val.StopName}
//                   >
//                     {val.StopName}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//         </div>
//         {/* <input
//           id="pac-input1"
//           type="text"
//           ref={pickupAddressRef}
//           placeholder="Enter pickup stop"
//           className="tags address"
//           tabIndex="14"
//           onChange={() => (current = "pickup")}
//           onBlur={() => {
//             current = "pickup";
//             addressChangeHandler();
//           }}
//         /> */}
//         {/* <br />
//         <input
//           id="pac-input2"
//           type="text"
//           ref={dropAddressRef}
//           placeholder="Enter drop stop"
//           className="tags address"
//           tabIndex="15"
//           onChange={() => (current = "drop")}
//           onBlur={() => {
//             current = "drop";
//             addressChangeHandler();
//           }}
//         /> */}
//       </div>
//       <div id="jay">
//         <div id="map"></div>
//       </div>
//       <div id="infowindow-content">
//         <span id="place-name" className="title"></span>
//         <br />
//         <span id="place-address"></span>
//       </div>
//     </div>
//   );
// };

// export default React.memo(Address);


import { useEffect, useRef, useState } from "react";
import React from "react";
import TextField from "@mui/material/TextField";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./Address.css";

let lat;
let lng;
let current = "";
let flag = 2;
let map, marker, infowindow;
const Address = (props) => {
  const pickupAddressRef = useRef();
  const dropAddressRef = useRef();
  const [pickupStopDetails, setPickupStopDetails] = useState();
  const [dropStopDetails, setDropStopDetails] = useState();
  const [isCustomStopSelected, setIsCustomStopSelected] = useState(false);
  const [isCustomStopSelectedD, setIsCustomStopSelectedD] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
    script.async = true;

    document.body.appendChild(script);
    // console.log("clicked");
  }, [isCustomStopSelected, isCustomStopSelectedD]);

  useEffect(() => {
    // console.log("a", props.riderData);
    if (props.edit && props.riderData) {
      pickupAddressRef.current.value = props.riderData[0]?.PickupPoint;
      dropAddressRef.current.value = props.riderData[0]?.DropPoint;
    }
  });

  function initMap() {
    map = new window.google.maps.Map(document.getElementById("map"), {
      center: props.edit
        ? {
            lat: +props.riderData[0].DropLL.split(",")[0],
            lng: +props.riderData[0].DropLL.split(",")[1],
          }
        : { lat: 23.0225, lng: 72.5714 },
      zoom: props.edit ? 15 : 11,
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
    });
    var input1 = document.getElementById("pac-input1");
    var input2 = document.getElementById("pac-input2");

    var autocomplete = [];
    autocomplete[0] = new window.google.maps.places.Autocomplete(input1);
    autocomplete[1] = new window.google.maps.places.Autocomplete(input2);
    var geocoder = new window.google.maps.Geocoder();

    autocomplete[0].bindTo("bounds", map);
    autocomplete[1].bindTo("bounds", map);
    infowindow = new window.google.maps.InfoWindow();
    var infowindowContent = document.getElementById("infowindow-content");
    infowindow.setContent(infowindowContent);
    marker = new window.google.maps.Marker({
      position: props.edit
        ? {
            lat: +props.riderData[0].DropLL.split(",")[0],
            lng: +props.riderData[0].DropLL.split(",")[1],
          }
        : "",
      map: map,
      draggable: true,
      animation: window.google.maps.Animation.DROP,
      // myTitle: props.edit ? props.riderData[0].DropPoint : "",
      anchorPoint: new window.google.maps.Point(0, -29),
    });

    // if (props.edit && flag > 0) {
    //   const infoWindow = new window.google.maps.InfoWindow();
    //   marker.addListener("mouseover", () => {
    //     infoWindow.close();
    //     infoWindow.setContent(marker.myTitle);
    //     infoWindow.open(marker.getMap(), marker);
    //   });
    //   flag--;
    // }

    window.google.maps.event.addListener(marker, "dragend", function (marker) {
      geocoder.geocode(
        {
          latLng: marker.latLng,
        },
        function (value) {
          infowindowContent.children["place-address"].textContent =
            value[0].formatted_address;
          infowindowContent.children["place-name"].textContent = "";
          if (current === "pickup")
            pickupAddressRef.current.value = value[0].formatted_address;
          else dropAddressRef.current.value = value[0].formatted_address;
          addressChangeHandler();
        }
      );
      // console.log(marker.formatted_address);
      lat = marker.latLng.lat();
      lng = marker.latLng.lng();
    });

    // for (let i = 0; i < 2; i++) {
    //   autocomplete[i].addListener("place_changed", function () {
    //     infowindow.close();
    //     marker.setVisible(false);
    //     var place = autocomplete[i].getPlace();
    //     if (!place.geometry || !place.geometry.location) {
    //       window.alert("No details available for input: '" + place.name + "'");
    //       return;
    //     }
    //     if (place.geometry.viewport) {
    //       console.log(place.geometry.location);
    //       map.setCenter(place.geometry.location);
    //       map.setZoom(18);
    //     } else {
    //       map.setCenter(place.geometry.location);
    //       map.setZoom(13);
    //     }
    //     marker.setPosition(place.geometry.location);
    //     marker.setVisible(true);
    //     lat = marker.getPosition().lat();
    //     lng = marker.getPosition().lng();
    //     console.log(marker.getPosition().lat());
    //     console.log(marker.getPosition().lng());
    //     infowindowContent.children["place-name"].textContent = place.name;
    //     infowindowContent.children["place-address"].textContent =
    //       place.formatted_address;
    //       // console.log("🚀 ~ place.formatted_address:", place.formatted_address)
    //     infowindow.open(map, marker);

    //      addressChangeHandler(place.formatted_address);
    //   });
    // }


    autocomplete.forEach((autocompleteInstance, index) => {
      autocompleteInstance.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        const place = autocompleteInstance.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        map.setCenter(place.geometry.location);
        map.setZoom(place.geometry.viewport ? 18 : 13);
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        lat = marker.getPosition().lat();
        lng = marker.getPosition().lng();
        // infowindowContent.children["place-name"].textContent = place.name;
        // infowindowContent.children["place-address"].textContent = place.formatted_address;
        // infowindow.open(map, marker);
        const address = place.formatted_address;
        if (index === 0) {
          current = "pickup";
        } else {
          current = "drop";
        }
        addressChangeHandler(address);
      });
    });
  }
  window.initMap = initMap;
  const addressChangeHandler = (address) => {
    setTimeout(() => {
      if (current === "pickup" && pickupAddressRef.current.value) {
        props.addressEntered(current, address || pickupAddressRef.current.value, lat, lng);
      } else if (current === "drop" && dropAddressRef.current.value) {
        props.addressEntered(current, address || dropAddressRef.current.value, lat, lng);
      }
    }, 1000);
  };
 

  const stopChnageHandler = (stopName, type) => {
    if (stopName.toLowerCase() === "custom") {
      marker.setDraggable(true);
      marker.setVisible(false);
      map.setCenter({ lat: 23.0225, lng: 72.5714 });
      map.setZoom(11);
      infowindow.close();
      if (type === "pickup") {
        setIsCustomStopSelected(true);
      } else {
        setIsCustomStopSelectedD(true);
      }
    } else {
      let desiredStop = props.stops.filter(
        (val) => val.StopName === stopName
      )[0];
      lat = +desiredStop.StopLatlong.split(",")[0];
      lng = +desiredStop.StopLatlong.split(",")[1];
      map.setCenter({ lat: lat, lng: lng });
      marker.setVisible(true);
      marker.setPosition({ lat: lat, lng: lng });
      marker.setDraggable(false);
      map.setZoom(18);
      infowindow.open(map, marker);
      infowindow.setContent(stopName);
      props.addressEntered(type, stopName, lat, lng);
      setIsCustomStopSelected(false);
      setIsCustomStopSelectedD(false);
    }
    if (type === "pickup") setPickupStopDetails(stopName);
    else setDropStopDetails(stopName);
  };

  const stopChnageHandler1 = (stopName, type) => {
    if (stopName.toLowerCase() === "custom") {
      marker.setDraggable(true);
      marker.setVisible(false);
      map.setCenter({ lat: 23.0225, lng: 72.5714 });
      map.setZoom(11);
      infowindow.close();

      setIsCustomStopSelectedD(true);
    } else {
      let desiredStop = props.stops.filter(
        (val) => val.StopName === stopName
      )[0];
      lat = +desiredStop.StopLatlong.split(",")[0];
      lng = +desiredStop.StopLatlong.split(",")[1];
      map.setCenter({ lat: lat, lng: lng });
      marker.setVisible(true);
      marker.setPosition({ lat: lat, lng: lng });
      marker.setDraggable(false);
      map.setZoom(18);
      infowindow.open(map, marker);
      infowindow.setContent(stopName);
      props.addressEntered(type, stopName, lat, lng);

      setIsCustomStopSelectedD(false);
    }
    if (type === "pickup") setPickupStopDetails(stopName);
    else setDropStopDetails(stopName);
  };

  return (
    <div style={{ height: "100%" }}>
      <h4 className="sub-header-title">
        {props.type === "sc" ? "Bus Stop Details" : "Bus/ Cab Stop Details"}
      </h4>
      <div id="pac-container" className="stop-details">
        <div className="stop-details__pickup">
          {props.stops.length <= 1 ||
          pickupStopDetails?.toLowerCase() === "custom" ? (
            <TextField
              id="pac-input1"
              label="From"
              autoFocus
              variant="standard"
              inputRef={pickupAddressRef}
              sx={{ width: "65%" }}
              // onChange={(e) => {
              //   current = "pickup";
              //   addressChangeHandler();
              // }}
              // value={pickupAddressRef.current ? pickupAddressRef.current.value : ""}
              onChange={(e) => {
                current = "pickup";
                addressChangeHandler(e.target.value);
              }}
            />
          ) : (
            <FormControl variant="standard" sx={{ width: "65%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                From
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={pickupStopDetails}
                onChange={(e) => stopChnageHandler(e.target.value, "pickup")}
                label="From"
              >
                {props.stops?.map((val) => (
                  <MenuItem
                    sx={{
                      display: "block",
                      width: "400px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    value={val.StopName}
                  >
                    {val.StopName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <ArrowRightAltIcon sx={{ alignSelf: "flex-end" }} />
          <TextField
            id="standard-basic"
            label="To"
            value={props.corpName}
            variant="standard"
            disabled
            sx={{ width: "25%" }}
          />
        </div>
        <div className="stop-details__drop">
          <TextField
            id="standard-basic"
            label="From"
            value={props.corpName}
            variant="standard"
            disabled
            sx={{ width: "25%" }}
          />
          <ArrowRightAltIcon sx={{ alignSelf: "flex-end" }} />
          {props.stops.length <= 1 ||
          dropStopDetails?.toLowerCase() === "custom" ? (
            <TextField
              id="pac-input2"
              label="To"
              variant="standard"
              autoFocus
              inputRef={dropAddressRef}
              sx={{ width: "65%" }}
              onChange={(e) => {
                current = "drop";
                addressChangeHandler(e.target.value);
              }}
            />
          ) : (
            <FormControl variant="standard" sx={{ width: "65%" }}>
              <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={dropStopDetails}
                onChange={(e) => stopChnageHandler1(e.target.value, "drop")}
                label="To"
              >
                {props.stops?.map((val) => (
                  <MenuItem
                    sx={{
                      display: "block",
                      width: "400px",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    value={val.StopName}
                  >
                    {val.StopName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {/* <input
          id="pac-input1"
          type="text"
          ref={pickupAddressRef}
          placeholder="Enter pickup stop"
          className="tags address"
          tabIndex="14"
          onChange={() => (current = "pickup")}
          onBlur={() => {
            current = "pickup";
            addressChangeHandler();
          }}
        /> */}
        {/* <br />
        <input
          id="pac-input2"
          type="text"
          ref={dropAddressRef}
          placeholder="Enter drop stop"
          className="tags address"
          tabIndex="15"
          onChange={() => (current = "drop")}
          onBlur={() => {
            current = "drop";
            addressChangeHandler();
          }}
        /> */}
      </div>
      <div id="jay">
        <div id="map"></div>
      </div>
      <div id="infowindow-content">
        <span id="place-name" className="title"></span>
        <br />
        <span id="place-address"></span>
      </div>
    </div>
  );
};

export default React.memo(Address);
